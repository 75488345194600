<template>
<!--  此为新增的手机端h5宣传页-->
  <div class="root">
<!--    <swiper :options="swiperOption" ref="mySwiper">-->
    <section class="page page1">
        <img class="page1-logo" src="../../assets/img/extraIntro/logo@2x.png" alt="财商少年logo">
      </section>

      <section class="page page2">
        <img class="top-line" src="../../assets/img/extraIntro/line@2x.png" alt="">
        <div class="page-title page2-title">
          <img src="../../assets/img/extraIntro/公司介绍@2x.png" alt="">
          <img src="../../assets/img/extraIntro/下划线@2x.png" alt="">
        </div>
        <div class="page2-text">
          在十二年的财商教育积累过程中，我们认为财商教育是基础教育，而不是兴趣教育，孩子财商的发展会影响到他一生的发展，影响TA的人生观、价值观、金钱观的塑造。
          我们希望通过《财商少年》系列课程协助孩子把财商融入生活，一起学习、修正计划并学会规划未来，从而给予孩子，一个美好而确定的未来。
        </div>
      </section>

      <section class="page page3">
        <img class="top-line" src="../../assets/img/extraIntro/line@2x.png" alt="">
        <div class="page3-item-container">
          <div class="page-title page3-title">
            <img src="../../assets/img/extraIntro/发展历程@2x.png" alt="">
            <img src="../../assets/img/extraIntro/下划线@2x.png" alt="">
          </div>
          <!--用图片替代背景图-->
          <img class="page3-img" src="../../assets/img/extraIntro/p3.png" alt="">
        </div>

      </section>

      <section class="page page4">
        <img class="top-line" src="../../assets/img/extraIntro/line@2x.png" alt="">
        <div class="page-title page4-title">
          <img src="../../assets/img/extraIntro/公司架构@2x.png" alt="">
          <img src="../../assets/img/extraIntro/下划线@2x.png" alt="">
        </div>
        <img class="duoduo" src="../../assets/img/extraIntro/duoduo.png" alt="">

        <div class="page4-flex-container">
          <div class="page4-flex-item">
            <div class="sub-title sub-title--left"
                 style="background: #e40073;">
              财商导师学院 TUTOR COLLEGE
            </div>
            <div class="page4-section">
              <p>我们拥有实战经验丰富的强大财商导师团队，形成了完备的导师培训体系，致力于培育更多优秀的少年财商导师。</p>
              <img src="../../assets/img/extraIntro/学院照片@2x.png" alt="">
            </div>
          </div>

          <div class="page4-flex-item">
            <div class="sub-title sub-title--right"
                 style="background: #ffe100;">
              财商少年学堂 CHILDREN COLLEGE
            </div>
            <div class="page4-section">
              <img src="../../assets/img/extraIntro/学堂照片@2x.png" alt="">
              <p>财商少年学堂与家庭一起打造孩子终身学习平台，开发了一系列的家庭亲子理财课程与活动。</p>
            </div>
          </div>

          <div class="page4-flex-item">
            <div class="sub-title sub-title--left"
                 style="background: #95c51a;">
              父母课堂 PARENTS COLLEGE
            </div>
            <div class="page4-section">
              <p>我们深知财商教育过程中，父母的重要性，从而开发打造了丰富的父母成长配套课程。</p>
              <img src="../../assets/img/extraIntro/父母课堂@2x.png" alt="">
            </div>
          </div>
        </div>

      </section>

      <section class="page page5">
        <img class="top-line" src="../../assets/img/extraIntro/line@2x.png" alt="">
        <div class="page-title page5-title">
          <img src="../../assets/img/extraIntro/品牌课程@2x.png" alt="">
          <img src="../../assets/img/extraIntro/下划线@2x.png" alt="">
        </div>
        <p class="page5-text">财商教育必学三大知识点 引导孩子走上“人生赢家”的道路</p>
        <div class="page5-poster-container-container">
          <div class="page5-poster-container">
            <img class="page5-poster" src="../../assets/img/extraIntro/poster1.png" alt="">
            <img class="page5-poster" src="../../assets/img/extraIntro/poster2.png" alt="">
          </div>
          <div class="page5-poster-container">
            <img class="page5-poster" src="../../assets/img/extraIntro/poster3.png" alt="">
            <img class="page5-poster" src="../../assets/img/extraIntro/poster4.png" alt="">
          </div>
        </div>
      </section>

      <section class="page page6">
        <img class="top-line" src="../../assets/img/extraIntro/line@2x.png" alt="">
        <div class="page-title page6-title">
          <img src="../../assets/img/extraIntro/品牌活动@2x.png" alt="">
          <img src="../../assets/img/extraIntro/下划线@2x.png" alt="">
        </div>
        <div class="page6-section-container">
          <div class="page6-section1">
            <img class="page6-section1-image" src="../../assets/img/extraIntro/素质教育@2x.png" alt="">
            <p class="page6-section1-title">少年财商素质教育导师</p>
            <img class="page6-section1-title1" src="../../assets/img/extraIntro/一个不会被@2x.png" alt="">
            <img class="page6-section1-title2" src="../../assets/img/extraIntro/ai替代的职业@2x.png" alt="">
          </div>

          <div class="page6-section2">
            <img class="page6-section2-image" src="../../assets/img/extraIntro/技能大赛@2x.png" alt="">
            <p class="page6-section2-title">“财商少年”全国财商技能大赛</p>
            <p class="page6-section2-text">少年儿童是祖国的未来和希望。我们的使命是通过举办“财商少年”全国大赛，
              打造一流财商教育活动品牌，让财商教育从少年儿童开始，让我们的下一代成为世界一流的高素质人才，
              为中华民族的复兴作出应有的贡献。
            </p>
          </div>

          <div class="page6-section3">
            <img src="../../assets/img/extraIntro/财商@2x.png" alt="">
            <img src="../../assets/img/extraIntro/智商@2x.png" alt="">
            <img src="../../assets/img/extraIntro/情商@2x.png" alt="">
          </div>
        </div>

      </section>
<!--      <div class="swiper-pagination" slot="pagination"></div>-->
<!--    </swiper>-->
  </div>
</template>

<script>
  // import 'swiper/dist/css/swiper.css'
  // import {swiper, swiperSlide} from 'vue-awesome-swiper'
  export default {
    name: "MExtra",
    // components: {
    //   swiper,
    //   swiperSlide,
    // },
    data() {
      return {
        swiperOption: {
          // cancelable: false
          pagination: {
            el: '.swiper-pagination',
          },
          direction : 'vertical',
          touchRatio: 3
        },
      }
    }
  }
</script>

<style lang="scss" scoped>

.root {
  background-color: #0068b7;
}
.swiper-container {
  background-color: #0068b7;
  width: 100%;
  height: 100vh;
  font-family: "Microsoft YaHei", sans-serif, monospace;
}
.page {
  // 比例是 375 * 667
  width: 100%;
  height: 178vw;
  position: relative;

  // 避免外边距重合
  &::before {
    content: '';
    display: table;
  }

  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: #0068b7;
}
.page-title {
  position: absolute;
  width: 133px;
  img {
    display: block;
    width: 100%;
    &:last-child {
      width: 80%;
      margin-top: 15px;
    }
  }
}
.page1 {
  background-image: url("../../assets/img/extraIntro/p1.png");
}
.page1-logo {
  position: absolute;
  width: 40vw;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  animation: slide-up 0.5s;
}

@keyframes slide-up {
  0% {
    bottom: -50%;
  }
  100% {
    bottom: 30px;
  }
}

.page2 {
  background-image: url("../../assets/img/extraIntro/p2.png");
  background-size: 90%;
  background-position: center bottom 10px;
}
.top-line {
  width: 100%;
  position: absolute;
  top: 0;
}
.page2-title {
  top: 165px;
  left: 36px;
}
.page2-text {
  color: #ffffff;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 36px;
  line-height: 2em;
  margin-top: 250px;
}
.page3 {
  /*background-image: url("../../assets/img/extraIntro/p3.png");*/
  /*background-size: 330px;*/
  /*background-position: center bottom 10px;*/
}
.page3-img {
  width: 330px;
  display: block;
  margin: 0 auto;
}
.page3-item-container {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.page3-title {
  position: relative;
  margin-left: 36px;
  /*top: 6vh;*/
  /*left: 36px;*/
}
.page4 {
  background-image: url("../../assets/img/extraIntro/p4.png");
}
.page4-title {
  top: 24px;
  left: 37px;
}
.duoduo {
  width: 84px;
  position: absolute;
  top: 26px;
  right: 0;
}
.page4-flex-container {
  display: flex;
  position: absolute;
  top: 13vh;
  height: 550px;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
}

// 半圆白字副标题
.sub-title {
  height: 27px;
  line-height: 27px;
  font-size: 16px;
  color: #ffffff;
  width: 255px;
  box-sizing: border-box;
  padding-left: 10px;
  margin-bottom: 26px;
}
.sub-title--left {
  border-bottom-right-radius: 14px;
  border-top-right-radius: 14px;
  left: 0;
}
.sub-title--right {
  float: right;
  border-bottom-left-radius: 14px;
  border-top-left-radius: 14px;
  right: 0;
  width: 280px;
}
.page4-section {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  height: 106px;
  img {
    height: 106px;
  }
  p {
    color: #ffffff;
    width: 35vw;
    font-size: 9px;
  }
}
.page5 {
  background-image: url("../../assets/img/extraIntro/p5.png");
}
.page5-title {
  top: 40px;
  left: 37px;
}
.page5-text {
  color: #ffffff;
  font-size: 10px;
  position: absolute;
  top: 110px;
  left: 37px;
}
.page5-poster-container-container {
  margin-top: 160px;
  /*display: flex;*/
  flex-direction: column;
  height: 500px;
  justify-content: space-evenly;
}
.page5-poster-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 25px;
}
.page5-poster {
  width: 110px;
  height: 160px;
  &:nth-child(1) {
    margin-right: 20px;
  }
}
.page6 {
  background-image: url("../../assets/img/extraIntro/p6.png");
  background-size: 95%;
  background-position: center top;
}
.page6-title {
  top: 38px;
  left: 37px;
}
.page6-section-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 100px;
  height: 150vw;
}
.page6-section1 {
  width: 100%;
  position: relative;
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}
.page6-section1-image {
  width: 332px;
  float: right;
}
.page6-section1-title {
  color: #ffffff;
  font-size: 11px;
  position: absolute;
  top: 46px;
  right: 190px;
}
// 导出的2倍图大小不一致, 导致不知道图片正确的大小是什么
.page6-section1-title1 {
  height: 22px;
  position: absolute;
  top: 95px;
  left: 30px;
}
.page6-section1-title2 {
  height: 22px;
  position: absolute;
  top: 135px;
  left: 30px;
}
.page6-section2 {
  position: relative;
}
.page6-section2-image {
  width: 343px;
}
.page6-section2-title {
  color: #ffffff;
  font-size: 11px;
  position: absolute;
  top: 28px;
  left: 165px;
}
.page6-section2-text {
  color: #ffffff;
  width: calc(100vw - 220px);
  position: absolute;
  font-size: 9pt;
  left: 204px;
  top: 70px;
  text-align: justify;
}
.page6-section3 {
  display: flex;
  justify-content: space-evenly;
  /*margin-top: 30px;*/
  img {
    height: 27px;
  }
}

::v-deep .swiper-pagination-bullet {
  background: #ffffff;
  opacity: 1;
}
::v-deep .swiper-pagination-bullet-active {
  background: rgb(31, 161, 217);
}
</style>
